import React from "react";
import logo from "img/logo/logo.webp";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import LinkText from "../link/link-text";
import { GiPapers } from "react-icons/gi";
import { MdDataThresholding } from "react-icons/md";

const Header = () => {
  const { t } = useTranslation();
  return (
    <header
      className="flex flex-row justify-between align-items-center flex-wrap
      font-extralight text-gray-900
      bg-neutral-bg dark:text-gray-300 dark:bg-dark-neutral-bg
      center md:flex-row lg:px-4"
    >
      <LinkText
        className="flex basis-full md:basis-1/2 gap-3 items-center py-4 px-2 text-sm lg:text-xl"
        href="/"
      >
        <img
          className="h-[30px] md:h-[40px]"
          src={logo}
          alt={t("vernadskyResearchBase")}
          width={30}
          height={40}
        />
        <span>{t("vernadskyResearchBaseShort")}</span>
      </LinkText>

      <hr className="basis-full flex-shrink-0 h-1 dark:opacity-10 md:hidden" />

      <LinkText
        className="flex basis-1/2 md:basis-auto gap-3 lg:gap-1 items-center p-2 md:ml-auto font-medium"
        href="/data"
      >
        <span className="text-xl">
          <MdDataThresholding />
        </span>
        <span className="text-sm md:text-l lg:block">{t("dataTitle")}</span>
      </LinkText>

      <LinkText
        className="flex basis-1/2 md:basis-auto gap-3 lg:gap-1 items-center p-2 font-medium"
        href="/related-papers"
      >
        <span className="text-xl">
          <GiPapers />
        </span>
        <span className="text-sm md:text-l lg:block">
          {t("relatedPapersTitle")}
        </span>
      </LinkText>
    </header>
  );
};

Header.propTypes = {
  isSideBarHidden: PropTypes.bool,
};

export default Header;
