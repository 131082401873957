export const formatMetaResponse = ({ data = {}, isActive = false }) => {
  const { range, dataStatsBar, ...meta } = data;

  return {
    startDate: range?.[0] || new Date("2017-10-05T14:48:00.000Z").toISOString(),
    endDate: range?.[1] || new Date().toISOString(),
    dataStatsBar,
    meta: Object.entries(meta).reduce(
      (acc, [metaKey, values]) => ({
        ...acc,
        [metaKey]: Object.values(values).map((value) => ({
          category: metaKey,
          key: value,
          value,
          isActive,
        })),
      }),
      {},
    ),
  };
};
