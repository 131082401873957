import axios from "axios";

const API_URL = "/api/v1";
const DATA_URL = `${API_URL}/data`;
const META_URL = `${API_URL}/meta`;

export const fetchData = async ({
  category,
  folderId,
  fileId,
  offset = 0,
  limit = 10,
  startDate,
  endDate,
  tags,
}) => {
  let route = `${DATA_URL}/${category}`;
  let params = {};

  if (folderId) {
    route += `/${folderId}`;
  }

  // Single file request doesn't have offset, startDate, endDate
  if (folderId && fileId) {
    route += `/${fileId}`;
  } else {
    // add only defined params to the request
    params = new URLSearchParams({
      limit,
      offset,
    });
    if (startDate) {
      params.append("startDate", startDate);
    }
    if (endDate) {
      params.append("endDate", endDate);
    }
    // Note: same tag key can have multiple values
    // this resolves to tag_key=tag_value1&tag_key=tag_value2
    Object.entries(tags).forEach(([key, values]) => {
      values.forEach((value) => {
        params.append(key, value);
      });
    });
  }

  const response = await axios({
    method: "get",
    url: route,
    params,
    headers: { Accept: "application/json" },
  });
  return response.data;
};

export const fetchMeta = async ({ category, folderId }) => {
  let route = `${META_URL}/${category}`;

  if (folderId) {
    route += `/${folderId}`;
  }

  const response = await axios({
    method: "get",
    url: route,
    headers: { Accept: "application/json" },
  });

  return response.data;
};
