import { useQuery } from "react-query";
import { DATA_KEYS } from "./api-keys";
import { fetchData } from "./api-service";

// GET paginated folders for a category [/data/:category]
// GET paginated folders for a category folder [/data/:category/:folderId]
// response:
// {
//   data: [
//     {
//       id: string,
//       name: string,
//       description: string,
//       descriptionEn: string,
//       count: number,
//       size: number, //? maybe string
//     },
//   ],
//   total: number,
//   offset: number,
//   limit: number,
// };
export function useFetchData({
  category,
  folderId,
  offset,
  startDate,
  endDate,
  tags,
  limit
}) {
  // Fetch data
  const { data, error, isLoading, isFetched, refetch } = useQuery({
    queryKey: DATA_KEYS.getFolderKeys({
      category,
      folderId,
      offset,
      startDate,
      endDate,
      tags,
    }),
    queryFn: async () =>
      await fetchData({
        category,
        folderId,
        offset,
        startDate,
        endDate,
        tags,
        limit
      }),
    enabled: false,
  });
  return { data, error, isLoading, isFetched, refetch };
}

// GET single file data [/data/:category/:folderId/:fileId]
// {
//   meta: {
//     _string: string,
//     ...
//   },
//   dataType: 'series'| ...
//   data: {
//     dates: [ISODate, ISODate, ...], // note: "dates" preserved key
//     x: [number, number, ...],
//     y: [number, number, ...],
//   },
// }
export function useFetchSingleData({ category, folderId, fileId }) {
  // Fetch data
  const { data, error, isLoading, isFetched } = useQuery(
    DATA_KEYS.getFileKeys({ category, folderId, fileId }),
    () =>
      fetchData({
        category,
        folderId,
        fileId,
      }),
  );

  return { data, error, isLoading, isFetched };
}
