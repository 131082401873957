export const MIN_HYDROMETEOROLOGY_DATE = "2022-01-01";

const HYDRO_COLUMNS_GENERAL = [
  {
    labelKey: "tblNameLbl",
    name: "name",
    helperKey: "description",
    className: "w-5/12",
  },
  {
    labelKey: "groupSubCategoryLbl",
    name: "type",
    type: "subCategory",
    className: "w-2/12",
  },
  {
    labelKey: "tblFileDateLbl",
    name: "date",
    className: "w-2/12",
  },
  {
    labelKey: "tblFileSizeLbl",
    name: "size",
    className: "w-1/12",
  },
];

export const HYDRO_COLUMNS = [
  ...HYDRO_COLUMNS_GENERAL,
  {
    labelKey: "tblHydroCountDaysLbl",
    name: "count",
    className: "w-2/12",
  },
];

export const HYDRO_FOLDER_COLUMNS = [
  ...HYDRO_COLUMNS_GENERAL,
  {
    labelKey: "tblHydroCountItemsLbl",
    name: "count",
    className: "w-2/12",
  },
];

export const SINGLE_FILES_COLUMNS_HYDRO = [
  ...HYDRO_FOLDER_COLUMNS,

  {
    labelKey: "",
    name: "link",
    type: "download",
    className: "w-1/12",
  },
];
