import React, { useMemo, useCallback } from "react";
import PropTypes from "prop-types";
import { LineChart, XAxis, YAxis, ResponsiveContainer } from "recharts";

import { useFetchSingleData } from "api/data/api-data-hooks";
import LoadingContainer from "components/loading";

import GeologyChart, {
  LABEL_POSITION,
  LINE_CHART_MARGIN,
} from "./geology-chart";
import { CATEGORIES } from "../../../api/data/constants";

// todo: make more generic
const formatChartData = ({ dates, X, Y, Z, G }) => ({
  xData: X?.map((value, index) => ({ date: dates[index], value })) ?? [],
  yData: Y?.map((value, index) => ({ date: index, value })) ?? [],
  zData: Z?.map((value, index) => ({ date: index, value })) ?? [],
  gData: G?.map((value, index) => ({ date: index, value })) ?? [],
  timeData:
    dates?.map((value, index) => ({ date: value?.slice(0, 16) ?? "" })) ?? [],
});

const GeoFileExpanded = ({ row: { id } = {}, category, folderId } = {}) => {
  const {
    isFetched: isFileDataFetched,
    isLoading: isLoadingFileData,
    error: fileDataError,
    data: fileData,
  } = useFetchSingleData({
    category,
    folderId,
    fileId: id,
    enabled: true,
  });

  const { xData, yData, zData, gData, timeData } = useMemo(
    () => formatChartData(fileData?.data?.data || {}),
    [fileData],
  );

  const getTooltipContent = useCallback(
    ({ label }) => {
      if (typeof label === "undefined") return null;

      return (
        <div className="bg-neutral dark:bg-dark-neutral-bg border-neutral color-neutral p-2 rounded-lg text-sm">
          {/*// fixme: set correct date format*/}
          <div className="text-xs mb-2">{xData[label].date}</div>
          <div>X: {xData[label]?.value ?? "-"} (nt)</div>
          <div>Y: {yData[label]?.value ?? "-"} (nt)</div>
          <div>Z: {zData[label]?.value ?? "-"}</div>
          <div>G: {gData[label]?.value ?? "-"}</div>
        </div>
      );
    },
    [xData, yData, zData, gData],
  );

  return (
    <LoadingContainer
      isLoading={isLoadingFileData || !isFileDataFetched}
      error={fileDataError}
      className="w-full flex flex-col shadow-inner py-8 items-center"
    >
      <div className="w-full flex flex-col shadow-inner py-8">
        <div className="w-full p-4 bg-gray-50 dark:bg-gray-800 rounded-md shadow-md mb-4">
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-x-4 gap-y-2">
            {Object.entries(fileData?.data?.meta || {}).map(([key, value]) => (
              <div
                key={key}
                className="flex justify-between border-b-[0.5px] pb-1"
              >
                <span className="text-sm font-medium text-gray-600 dark:text-gray-300">
                  {key}
                </span>
                <span className="text-sm text-gray-800 dark:text-gray-100">
                  {value}
                </span>
              </div>
            ))}
          </div>
        </div>
        {/* X */}
        <GeologyChart
          chartData={xData}
          yLabel={{
            value: "X (nt)",
            ...LABEL_POSITION,
          }}
          color="#FF0000"
          getTooltipContent={getTooltipContent}
          showLine={false}
        />
        {/* Y */}
        <GeologyChart
          chartData={yData}
          yLabel={{
            value: "Y (nt)",
            ...LABEL_POSITION,
          }}
          color="#82ca9d"
          getTooltipContent={getTooltipContent}
        />
        {/* Z */}
        <GeologyChart
          chartData={zData}
          yLabel={{
            value: "Z (nt)",
            ...LABEL_POSITION,
          }}
          color="#1E90FF"
          domain={["dataMin", "dataMax"]}
          getTooltipContent={getTooltipContent}
        />
        {/* Missing data */}
        <GeologyChart
          chartData={gData}
          yLabel={{
            value: "G (difference - nt)",
            ...LABEL_POSITION,
            dy: -60,
          }}
          label="G (difference - nt)"
          color="#767681"
          getTooltipContent={getTooltipContent}
          domain={["dataMin - 0.1", "dataMax + 0.11"]}
        />
        <ResponsiveContainer width="100%" height={30}>
          <LineChart data={timeData} margin={LINE_CHART_MARGIN}>
            <YAxis />
            <XAxis granularity={5} dataKey="date" className="text-xs" />
          </LineChart>
        </ResponsiveContainer>
      </div>
    </LoadingContainer>
  );
};

GeoFileExpanded.propTypes = {
  row: PropTypes.shape({
    id: PropTypes.string,
  }),
  category: PropTypes.oneOf(Object.values(CATEGORIES)),
  folderId: PropTypes.string,
};

export default GeoFileExpanded;
