import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import LayoutWithSidebar from "layout/layout-with-sidebar";
import BreadCrumbs from "components/breadcrumbs";


import TableWithPagination from "components/table/table-with-pagination";
import TagH1 from "components/typography/h1";
import LoadingContainer from "components/loading";

import { CATEGORIES } from "api/data/constants";
import { CATEGORIES_CONFIG } from "../categories-settings";

import { MIN_GEOLOGY_DATE, GEO_LIST_COLUMNS } from "./constants";
import { useData } from "../hooks/useData";
import Measurement from "./geo-measurement-text";

const GeoListPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const category = CATEGORIES.GEOLOGY_AND_GEOPHYSICS;
  const categoryTitle = t(CATEGORIES_CONFIG[category]?.labelKey);

  // DERIVED STATE
  const {
    isDataFetched,
    data,
    dataError,
    isLoadingData,
    // pagination
    currentPage,
    setCurrentPage,
    itemsPerPage,
    setItemsPerPage,
  } = useData({
    category,
    defaultMinStartDate: MIN_GEOLOGY_DATE,
  });

  return (
    <LayoutWithSidebar>
      <div>
        <TagH1 text={categoryTitle} />

        <BreadCrumbs
          currentPage={categoryTitle}
          links={[{ name: t("dataTitle"), link: "/data" }]}
        />

        <div className="overflow-x-scroll">
          <div className="min-w-[400px]">
            <div className="flex flex-col gap-8">
              <Measurement />
              <LoadingContainer
                isLoading={isLoadingData || !isDataFetched}
                error={dataError}
              >
                <section
                  className="border bg-neutral-bg border-neutral dark:bg-dark-neutral-bg dark:border-dark-neutral-border
                  rounded-2xl p-8 scrollbar-hide lg:overflow-x-hidden"
                >
                  <TableWithPagination
                    onRowClick={({ id }) =>
                      navigate(`/data/geology-and-geophysics/${id}`)
                    }
                    data={data?.data || []}
                    columns={GEO_LIST_COLUMNS}
                    total={data?.total || 0}
                    setCurrentPage={setCurrentPage}
                    currentPage={currentPage}
                    itemsPerPage={itemsPerPage}
                    setItemsPerPage={setItemsPerPage}
                  />
                </section>
              </LoadingContainer>
            </div>
          </div>
        </div>
      </div>
    </LayoutWithSidebar>
  );
};

export default GeoListPage;