import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import BreadCrumbs from "components/breadcrumbs";
import LayoutWithSidebar from "layout/layout-with-sidebar";
import Meta from "components/meta";
import TableWithPagination from "components/table/table-with-pagination";
import TagH1 from "components/typography/h1";
import LoadingContainer from "components/loading";

import { CATEGORIES } from "api/data/constants";

import { CATEGORIES_CONFIG } from "../categories-settings";
import { useData } from "../hooks/useData";
import { MIN_GEOLOGY_DATE, GEO_SINGLE_FOLDER_COLUMNS } from "./constants";
import GeoFileExpanded from "./geo-file-expanded";

const GeoFolderPage = ({ id }) => {
  const { t } = useTranslation();
  const category = CATEGORIES.GEOLOGY_AND_GEOPHYSICS;

  // DERIVED STATE
  const {
    // meta
    isMetaFetched,
    tags,
    setTags,
    isLoadingTags,
    tagsError,
    allowedRange,
    range,
    setRange,
    // data
    isDataFetched,
    data,
    dataError,
    isLoadingData,
    // pagination
    currentPage,
    setCurrentPage,
    itemsPerPage,
    setItemsPerPage,
  } = useData({
    category,
    folderId: id,
    defaultMinStartDate: MIN_GEOLOGY_DATE,
  });

  // HANDLERS
  const handleTagClick = (tag) => {
    setTags((prevState) => {
      return {
        ...prevState,
        [tag.category]: [...prevState[tag.category]].map((t) => ({
          ...t,
          isActive: t.key === tag.key ? !t.isActive : t.isActive,
        })),
      };
    });
  };

  const title = data?.data?.title || "";

  return (
    <LayoutWithSidebar>
      <div>
        <TagH1 text={title} />

        <BreadCrumbs
          currentPage={title}
          links={[
            { name: t("dataTitle"), link: "/data" },
            {
              name: t(CATEGORIES_CONFIG[category].labelKey),
              link: `/data/${category}`,
            },
          ]}
        />

        <div className="overflow-x-scroll">
          <div className="min-w-[400px]">
            <div className="flex flex-col gap-8">
              <Meta
                isLoading={isLoadingTags || !isMetaFetched}
                tags={tags}
                tagsError={tagsError}
                handleTagClick={handleTagClick}
                range={range}
                setRange={setRange}
                allowedRange={allowedRange}
              />

              <LoadingContainer
                isLoading={isLoadingData || !isDataFetched}
                error={dataError}
              >
                <section
                  className="border bg-neutral-bg border-neutral dark:bg-dark-neutral-bg dark:border-dark-neutral-border
                  rounded-2xl p-8 scrollbar-hide lg:overflow-x-hidden"
                >
                  <TableWithPagination
                    data={data?.data?.data || []}
                    columns={GEO_SINGLE_FOLDER_COLUMNS}
                    total={data?.total || 0}
                    setCurrentPage={setCurrentPage}
                    currentPage={currentPage}
                    itemsPerPage={itemsPerPage}
                    setItemsPerPage={setItemsPerPage}
                    ExpandedRowComponent={({ row }) => (
                      <GeoFileExpanded
                        row={row}
                        category={category}
                        folderId={id}
                      />
                    )}
                  />
                </section>
              </LoadingContainer>
            </div>
          </div>
        </div>
      </div>
    </LayoutWithSidebar>
  );
};

GeoFolderPage.propTypes = {
  id: PropTypes.string,
};

export default GeoFolderPage;
