import React, { useMemo } from "react";
import {
  XAxis,
  YAxis,
  Legend,
  LineChart,
  ResponsiveContainer,
  Line,
  CartesianGrid,
} from "recharts";

const LineChartCustomized = ({
  data,
  minDiff = -10,
  maxDiff = 10,
  name,
} = {}) => {
  const {
    chartData,
    minValue,
    maxValue = 1,
  } = useMemo(() => {
    return {
      chartData: (data?.values || []).map(
        (value, idx) => ({ date: data?.dates?.[idx], value }) || {},
      ),
      minValue: Math.min(...(data?.values || [])) || 0,
      maxValue: Math.max(...(data?.values || [])) || 1,
    };
  }, [data]);

  if (!data || !chartData.length) {
    return null;
  }

  return (
    <div className="w-full h-48">
      <ResponsiveContainer width="100%" height="100%">
        <LineChart
          // layout="vertical"
          data={chartData}
          margin={{ top: 20, right: 0, left: 0, bottom: 20 }}
        >
          <CartesianGrid strokeDasharray="3 3" stroke="#313442" />
          <XAxis dataKey="date" />
          <YAxis domain={[minValue + minDiff, maxValue + maxDiff]} />
          <Legend />
          <Line dataKey="value" name={name} stroke="#0077b6" />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export default LineChartCustomized;
