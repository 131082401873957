import { useQuery } from "react-query";
import { META_KEYS } from "./api-keys";
import { fetchMeta } from "./index";

// GET meta a category [/meta/:category]
// GET meta a category folder [/meta/:category/:folderId]
// {
//   data: {
//     [key]: [string, string, ...],
//     ...
//     range:  [ISO date, ISO date], // [start, end]
//   },
// };
export function useFetchMeta({ category, folderId }) {
  // Fetch data
  const { data, error, isLoading, isFetched } = useQuery(
    META_KEYS.getFolderKeys({ category, folderId }),
    () =>
      fetchMeta({
        category,
        folderId,
      }),
  );
  return { data, error, isLoading, isFetched };
}
