import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import BreadCrumbs from "components/breadcrumbs";
import LayoutWithSidebar from "layout/layout-with-sidebar";
import LoadingContainer from "components/loading";
import Meta from "components/meta";
import TableWithPagination from "components/table/table-with-pagination";
import TagH1 from "components/typography/h1";
import Scale from "components/charts/scale";

import { CATEGORIES } from "api/data/constants";

import { CATEGORIES_CONFIG } from "pages/data/categories-settings";
import { useData } from "pages/data/hooks/useData";

import HydroFileExpanded from "./hydro-file-expanded";
import { HYDRO_FOLDER_COLUMNS, MIN_HYDROMETEOROLOGY_DATE } from "./constants";

const HydroFolderPage = ({ id }) => {
  const { t } = useTranslation();
  const category = CATEGORIES.HYDROMETEOROLOGY;
  const categoryTitle = t(CATEGORIES_CONFIG[category].labelKey);

  // STATE
  // const [activeBarIndex, setActiveBarIndex] = useState();

  // DERIVED STATE
  const {
    // meta
    isMetaFetched,
    dataStatsBar,
    tags,
    setTags,
    isLoadingTags,
    tagsError,
    allowedRange,
    range,
    setRange,
    // data
    isDataFetched,
    data,
    dataError,
    isLoadingData,
    // pagination
    currentPage,
    setCurrentPage,
    itemsPerPage,
    setItemsPerPage
  } = useData({
    category,
    folderId: id,
    defaultMinStartDate: MIN_HYDROMETEOROLOGY_DATE,
  });

  const title = data?.data?.title || "";

  // HANDLERS
  const handleTagClick = (tag) => {
    setTags((prevState) => {
      return {
        ...prevState,
        [tag.category]: [...prevState[tag.category]].map((t) => ({
          ...t,
          isActive: t.key === tag.key ? !t.isActive : t.isActive,
        })),
      };
    });
  };

  return (
    <LayoutWithSidebar>
      <div>
        <TagH1 text={title} />

        <BreadCrumbs
          currentPage={title}
          links={[
            { name: t("dataTitle"), link: "/data" },
            {
              name: categoryTitle,
              link: `/data/${category}`,
            },
          ]}
        />

        <div cclassName="overflow-visible">
          <div className="min-w-[400px]">
            <div className="flex flex-col gap-8">
              <Scale
                data={dataStatsBar}
                name={t("measurementCount")}
              // activeIndex={activeBarIndex}
              // setActiveIndex={setActiveBarIndex}
              />

              <Meta
                isLoading={isLoadingTags || !isMetaFetched}
                tags={tags}
                tagsError={tagsError}
                handleTagClick={handleTagClick}
                range={range}
                setRange={setRange}
                allowedRange={allowedRange}
              />

              <LoadingContainer
                isLoading={isLoadingData || !isDataFetched}
                error={dataError}
              >
                <section
                  className="border bg-neutral-bg border-neutral dark:bg-dark-neutral-bg dark:border-dark-neutral-border
                      rounded-2xl p-8 scrollbar-hide lg:overflow-x-hidden"
                >
                  <TableWithPagination
                    data={data?.data?.data || []}
                    columns={HYDRO_FOLDER_COLUMNS}
                    total={data?.total || 0}
                    setCurrentPage={setCurrentPage}
                    currentPage={currentPage}
                    itemsPerPage={itemsPerPage}
                    setItemsPerPage={setItemsPerPage}
                    ExpandedRowComponent={({ row }) => (
                      <HydroFileExpanded
                        row={row}
                        category={category}
                        folderId={id}
                      />
                    )}
                  />
                </section>
              </LoadingContainer>
            </div>
          </div>
        </div>
      </div>
    </LayoutWithSidebar>
  );
};

HydroFolderPage.propTypes = {
  category: PropTypes.oneOf(Object.values(CATEGORIES)),
};

export default HydroFolderPage;
