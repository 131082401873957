import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

export const Tags = ({
  title = "",
  tags = {},
  onClick = () => {},
  className,
  groupByCategory = false,
} = {}) => {
  const { t } = useTranslation();

  return (
    <div
      className={
        className ??
        "border bg-neutral-bg border-neutral dark:bg-dark-neutral-bg dark:border-dark-neutral-border rounded-2xl p-6"
      }
    >
      <div className="flex justify-between border-neutral dark:border-dark-neutral-border flex-col">
        {title && (
          <h3 className="text-base leading-5 text-gray-1100 font-semibold dark:text-gray-dark-1100 mb-[15px]">
            {title}
          </h3>
        )}

        <div className="flex flex-col gap-4">
          {Object.entries(tags).map(([group, items = []], index) => (
            <div
              key={index}
              className={
                groupByCategory
                  ? "grid grid-cols-[1fr_minmax(50%,_3fr)]"
                  : "flex items-center gap-2"
              }
            >
              {groupByCategory && (
                <h4
                  className="text-sm leading-3 text-gray-500 dark:text-gray-dark-500
                  py-2 overflow-x-hidden overflow-ellipsis whitespace-nowrap capitalize"
                >
                  {t(group)}:
                </h4>
              )}
              <div className="flex flex-wrap gap-2">
                {[...items].map((tag) => (
                  <button
                    className={`
                          rounded-[8px]
                          leading-3 text-[10px]
                          py-2 px-[18px] 
                          ${
                            tag.isActive
                              ? "text-white bg-color-brands dark:bg-color-brands"
                              : "dark:text-gray-dark-500 text-gray-500 bg-neutral dark:bg-dark-neutral-border "
                          }`}
                    key={(tag.key || tag.name || index).toString()}
                    onClick={() => onClick(tag)}
                  >
                    {!groupByCategory && tag.category && `${t(tag.category)}: `}
                    {t(tag.name) || tag.key}
                  </button>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export const TAG_SHAPE = PropTypes.shape({
  key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  // optional for translation/view
  name: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.bool,
  ]),
  // optional for link
  link: PropTypes.string,
  isActive: PropTypes.bool,
});

Tags.propTypes = {
  title: PropTypes.string,
  tags: PropTypes.objectOf(PropTypes.arrayOf(TAG_SHAPE)),

  onClick: PropTypes.func,
  groupByKey: PropTypes.string,
  className: PropTypes.string,
};
