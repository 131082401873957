import React, { useMemo } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Legend,
  ResponsiveContainer,
  Cell,
} from "recharts";
import PropTypes from "prop-types";

const Scale = ({ data = [], name, activeIndex, setActiveIndex = () => {} }) => {
  const { chartData, maxValue = 1 } = useMemo(() => {
    return {
      chartData: data.map(
        (item) =>
          Object.entries(item).map(([date, value]) => ({ date, value }))?.[0] ||
          {},
      ),
      maxValue: Math.max(...data.map((item) => item.value)) || 1,
    };
  }, [data]);

  const hasActive =
    typeof activeIndex !== "undefined" &&
    activeIndex >= 0 &&
    activeIndex < data.length;

  const handleClick = (data, index) => {
    if (activeIndex === index) {
      setActiveIndex();
    } else {
      setActiveIndex(index);
    }
  };

  if (!data.length) {
    return null;
  }

  return (
    <div className="w-full h-48">
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          data={chartData}
          margin={{ top: 20, right: 0, left: 0, bottom: 20 }}
        >
          <XAxis dataKey="date" />
          <YAxis domain={[0, maxValue]} />
          <Legend />
          <Bar dataKey="value" onClick={handleClick} name={name} fill="0077b6">
            {chartData.map((entry, index) => (
              <Cell
                cursor="pointer"
                fill={
                  !hasActive || activeIndex === index ? "#0077b6" : "#535362"
                }
                key={`cell-${index}`}
              />
            ))}
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

Scale.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  name: PropTypes.string,
  activeIndex: PropTypes.number,
  setActiveIndex: PropTypes.func,
};

export default Scale;
