import PropTypes from "prop-types";
import { TiWarning } from "react-icons/ti";
import { useTranslation } from "react-i18next";

export default function LoadingContainer({
  isLoading,
  error,
  children,
  className = "border bg-neutral-bg border-neutral dark:bg-dark-neutral-bg dark:border-dark-neutral-border rounded-2xl p-6",
}) {
  const { t } = useTranslation();

  if (isLoading) {
    return <div className={className}>{t("loadingText")}</div>;
  }

  if (!!error) {
    return (
      <div className={className}>
        <div className="flex items-center gap-2">
          {" "}
          <TiWarning />
          {t("loadingErrorText")}
        </div>
        {error?.message && (
          <div className="text-sm italic mt-8 opacity-50">{error?.message}</div>
        )}
      </div>
    );
  }

  return children;
}

LoadingContainer.propTypes = {
  isLoading: PropTypes.bool,
  error: PropTypes.shape({
    message: PropTypes.string,
  }),
  children: PropTypes.node,
};
