import React from "react";
import PropTypes from "prop-types";
import { getPaginationRange } from "./utils";

const limits = [10, 25, 50, 100];
const Pagination = ({
  className = "",
  total,
  setCurrentPage = () => { },
  currentPage,
  setItemsPerPage,
  itemsPerPage = 10,
}) => {
  const pagination = getPaginationRange({
    currentPage,
    len: Math.ceil(total / itemsPerPage),
  });

  return (
    <div className={`flex items-center gap-1 flex-wrap ${className}`}>

      {pagination.map((item, index) => (
        <button
          key={index}
          type="button"
          onClick={() => typeof item === "number" && setCurrentPage(item)}
          className={`btn border-0 shadow-none min-w-12
            ${currentPage === item
              ? "bg-color-brands text-white"
              : "bg-transparent text-black dark:text-white"
            } 
            text-sm capitalize leading-4 font-semibold
            hover:bg-color-brands hover:text-white focus:text-white`}
          disabled={item === "..."}
        >
          {item}
        </button>
      ))}
      <span>|</span>
      <div className="flex space-x-2 px-2">
        {limits.map((limit, index) => (
          <span key={limit} className="flex items-center">
            <button
              onClick={() => {
                setItemsPerPage(limit);
                setCurrentPage(1);
              }}
              className={`btn border-0 shadow-none min-w-12
        ${itemsPerPage === limit
                  ? "bg-color-brands text-white"
                  : "bg-transparent text-black dark:text-white"
                } 
        text-sm capitalize leading-4 font-semibold
        hover:bg-color-brands hover:text-white focus:text-white`}
            >
              {limit}
            </button>
          </span>
        ))}
      </div>
    </div>
  );
};

export const PAGINATION_SHAPE = {
  className: PropTypes.string,
  total: PropTypes.number.isRequired,
  itemsPerPage: PropTypes.number,
  setCurrentPage: PropTypes.func.isRequired,
  currentPage: PropTypes.number.isRequired,
};

Pagination.propTypes = PAGINATION_SHAPE;

export default Pagination;
