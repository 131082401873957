export const MIN_GEOLOGY_DATE = "2018-01-01";

const GENERIC_GEO_COLUMNS = [
  {
    labelKey: "nameLbl",
    name: "name",
    helperKey: "description",
    helperKeyEn: "descriptionEn",
    className: "w-6/24",
  },
  {
    labelKey: "typeLbl",
    name: "type",
    className: "w-2/24",
  },
  {
    labelKey: "dateLbl",
    name: "date",
    className: "w-2/24",
  },
  {
    labelKey: "sizeLbl",
    name: "size",
    className: "w-1/24",
  },
];

export const GEO_LIST_COLUMNS = [
  ...GENERIC_GEO_COLUMNS,
  {
    labelKey: "countLbl",
    name: "count",
    className: "w-1/24",
  },
];
export const GEO_SINGLE_FOLDER_COLUMNS = [
  ...GENERIC_GEO_COLUMNS,

  {
    labelKey: "",
    name: "link",
    type: "download",
    className: "w-1/12",
  },
];
