import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { CATEGORIES } from "api/data/constants";

import BreadCrumbs from "components/breadcrumbs";
import LayoutWithSidebar from "layout/layout-with-sidebar";
import LoadingContainer from "components/loading";
import TableWithPagination from "components/table/table-with-pagination";
import TagH1 from "components/typography/h1";
import { USER_ROLES } from "components/groups/user-groups-settings";

import { useData } from "../hooks/useData";

import { CATEGORIES_CONFIG } from "../categories-settings";
import { MIN_HYDROMETEOROLOGY_DATE, HYDRO_COLUMNS } from "./constants";
import Geomagnetic from "./geomagnetic-text";


const HydroMeteorologyList = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const category = CATEGORIES.HYDROMETEOROLOGY;
  const title = t(CATEGORIES_CONFIG[category].labelKey);

  const handleRowClick = useCallback(
    ({ id }) => {
      navigate(`/data/${category}/${id}`);
    },
    [category, navigate],
  );

  // DERIVED STATE
  const {
    isDataFetched,
    data,
    dataError,
    isLoadingData,
    // pagination
    currentPage,
    setCurrentPage,
    itemsPerPage,
    setItemsPerPage,
  } = useData({
    category,
    defaultMinStartDate: MIN_HYDROMETEOROLOGY_DATE,
  });

  return (
    <LayoutWithSidebar>
      <div>
        <TagH1 text={title} />

        <BreadCrumbs
          currentPage={title}
          links={[{ name: t("dataTitle"), link: "/data" }]}
        />

        <div className="flex flex-col gap-8">
          <Geomagnetic />

          <LoadingContainer
            isLoading={isLoadingData || !isDataFetched}
            error={dataError}
          >
            <section
              className="border bg-neutral-bg border-neutral dark:bg-dark-neutral-bg dark:border-dark-neutral-border
                  rounded-2xl p-8 scrollbar-hide lg:overflow-x-hidden"
            >
              <TableWithPagination
                onRowClick={handleRowClick}
                data={data?.data || []}
                columns={HYDRO_COLUMNS}
                total={data?.total || 0}
                setCurrentPage={setCurrentPage}
                currentPage={currentPage}
                itemsPerPage={itemsPerPage}
                setItemsPerPage={setItemsPerPage}
              />
            </section>
          </LoadingContainer>
        </div>
      </div>
    </LayoutWithSidebar>
  );
};
HydroMeteorologyList.propTypes = {
  role: PropTypes.oneOf(Object.values(USER_ROLES)),
  category: PropTypes.oneOf(Object.values(CATEGORIES)),
};

export default HydroMeteorologyList;