import { useEffect, useState, useMemo, useCallback } from "react";
import { useFetchMeta } from "api/data/api-meta-hooks";
import { formatMetaResponse } from "api/data/utils";
import { useFetchData } from "api/data/api-data-hooks";
import { useLocation, useNavigate } from "react-router-dom";
export function useData({ category, folderId, defaultMinStartDate }) {
  // STATES
  const [isParamsLoadDone, setIsParamsLoadDone] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const params = new URLSearchParams(location.search);
  const [currentPage, setCurrentPage] = useState(
    Number(params.get("page")) || 1,
  );
  const [itemsPerPage, setItemsPerPage] = useState(Number(params.get("limit")) || 10,);

  // min and max dates for the datepicker
  const [allowedRange, setAllowedRange] = useState({
    startDate: new Date(defaultMinStartDate),
    endDate: null,
  });
  const [range, setRange] = useState({
    startDate: null,
    endDate: null,
  });
  const [dataStatsBar, setDataStatsBar] = useState([]);
  const [tags, setTags] = useState({});

  // DERIVES STATES
  const activeTags = useMemo(
    () =>
      Object.values(tags)
        .flat(2)
        .filter(({ isActive }) => !!isActive)
        .reduce((acc, { key, category }) => {
          acc[category] = [...(acc[category] || []), key];
          return acc;
        }, {}),
    [tags],
  );

  // FETCH: META
  const {
    isFetched: isMetaFetched,
    isLoading: isLoadingTags,
    data: metaData,
    error: tagsError,
  } = useFetchMeta({ category, folderId });

  useEffect(() => {
    if (!isLoadingTags && !tagsError && isMetaFetched) {
      const {
        startDate,
        endDate,
        meta,
        dataStatsBar: stats,
      } = formatMetaResponse({
        data: metaData,
      });

      const start = startDate
        ? new Date(startDate)
        : new Date(defaultMinStartDate);
      const end = endDate ? new Date(endDate) : new Date();

      setAllowedRange({
        startDate: start,
        endDate: end,
      });
      setRange({
        startDate: start,
        endDate: end,
      });
      setTags(meta);

      setDataStatsBar(stats);

      setIsParamsLoadDone(true);
    }
  }, [isMetaFetched, isLoadingTags, tagsError, metaData, defaultMinStartDate]);

  // FETCH: DATA (only after meta is fetched)
  const {
    isFetched: isDataFetched,
    isLoading: isLoadingData,
    error: dataError,
    data,
    refetch,
  } = useFetchData({
    category,
    folderId,
    limit: itemsPerPage,
    offset: itemsPerPage * (currentPage - 1),
    startDate: range.startDate?.toISOString(),
    endDate: range.endDate?.toISOString(),
    tags: activeTags,
    // query settings
    refetch: false,
    enabled: false,
  });
  const updatePageInUrl = useCallback((page, limit) => {
    const urlParams = new URLSearchParams(location.search);
    urlParams.set("page", page);
    urlParams.set("limit", limit);
    navigate(`${location.pathname}?${urlParams.toString()}`, { replace: true });
  }, [navigate, location.pathname, location.search]);

  useEffect(() => {
    if (isMetaFetched && isParamsLoadDone) {
      updatePageInUrl(currentPage, itemsPerPage);
      refetch();
    }
  }, [
    isMetaFetched,
    isParamsLoadDone,
    currentPage,
    range,
    activeTags,
    refetch,
    updatePageInUrl,
    itemsPerPage
  ]);

  return {
    // META
    isMetaFetched,
    isLoadingTags,
    tagsError,

    // allowed date range (min and max date, where we have data)
    allowedRange,

    // dates with data (for the stats bar)
    dataStatsBar,

    // all available meta tags
    tags,
    setTags,

    // [used for GET request] date range
    range,
    setRange,

    // [used for GET request offset] current page
    currentPage,
    setCurrentPage,

    // DATA
    isLoadingData,
    isDataFetched,
    dataError,
    data,
    //PAGINATION
    itemsPerPage,
    setItemsPerPage,
  };
}