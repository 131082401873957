export const getPaginationRange = ({ currentPage, len }) => {
  const delta = 2;
  const range = [];
  const left = Math.max(2, currentPage - delta);
  const right = Math.min(len - 1, currentPage + delta);

  if (left > 2) range.push("...");
  for (let i = left; i <= right; i++) {
    range.push(i);
  }
  if (right < len - 1) range.push("...");

  return [1, ...range, len].filter((num, i, arr) => num !== arr[i - 1]);
};
