import React from "react";
import ReactDOM from "react-dom/client";
import { QueryClient, QueryClientProvider } from "react-query";
import "./main.css";
import "./i18n";

import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";

import HomePage from "./pages/home/home-page";
import NotFound from "./pages/not-found";
import Explore from "./pages/explore";
import RelatedPages from "./pages/related-pages/related-pages";
import ResearchStructure from "./pages/research-structure/research-structure";

// DATA PAGES
import DataPage from "./pages/data/data-page";

// AUTH PAGES
// import LoginPage from "./pages/auth/login-page";
// import RegistrationPage from "./pages/auth/registration-page";
// import ResetPasswordPage from "./pages/auth/reset-password-page";
// import SignUpSuccess from "./pages/auth/sign-up-success";
// import UpdatePasswordPage from "./pages/auth/update-password-page";
// import { USER_ROLES } from "./components/groups/user-groups-settings";
// import Account from "./pages/account";
// import EditGroup from "./pages/account/edit-group";

// EXAMPLES PAGES
// import Uploads from "./pages/uploads";
// import ProductDetails from "./pages/product-details/product-details";
// import PeopleGroup from "./pages/examples/people-group";
// import EditExperiment from "./pages/examples/edit-experiment";
// import FormGroup from "./pages/examples/form-group";
// import Charts from "./pages/examples/charts";
// import Scale from "./components/charts/scale";
// import SelectedPapers from "./pages/related-pages/selected-papers";

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <Router>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/explore" element={<Explore />} />
          <Route path="/related-papers" element={<RelatedPages />} />
          <Route path="/research-structure" element={<ResearchStructure />} />

          {/* Data pages: */}
          <Route path="/data" element={<DataPage />} />
          <Route path="/data/:category/" element={<DataPage />} />
          <Route path="/data/:category/:id" element={<DataPage />} />

          {/* Auth pages: */}
          {/*<Route path="/login" element={<LoginPage />} />*/}
          {/*<Route path="/registration" element={<RegistrationPage />} />*/}
          {/*<Route path="/sign-up-success" element={<SignUpSuccess />} />*/}
          {/*<Route path="/request-reset-password" element={<ResetPasswordPage />} />*/}
          {/*<Route path="/update-password" element={<UpdatePasswordPage />} />*/}

          {/* User management pages : */}
          {/*<Route path="/account" element={<Account />} />*/}

          {/*todo: replace with role based view*/}
          {/*<Route*/}
          {/*  path="/account/user"*/}
          {/*  element={<Account role={USER_ROLES.USER} />}*/}
          {/*/>*/}
          {/*<Route*/}
          {/*  path="/account/member"*/}
          {/*  element={<Account role={USER_ROLES.MEMBER} />}*/}
          {/*/>*/}
          {/*<Route*/}
          {/*  path="/account/chief"*/}
          {/*  element={<Account role={USER_ROLES.CHIEF} />}*/}
          {/*/>*/}
          {/*<Route*/}
          {/*  path="/account/admin"*/}
          {/*  element={<Account role={USER_ROLES.ADMIN} />}*/}
          {/*/>*/}
          {/*<Route*/}
          {/*  path="/account/edit-group"*/}
          {/*  element={<EditGroup role={USER_ROLES.ADMIN} groupId="abc" />}*/}
          {/*/>*/}
          {/*<Route*/}
          {/*  path="/account/add-group"*/}
          {/*  element={<EditGroup role={USER_ROLES.ADMIN} />}*/}
          {/*/>*/}

          {/* Mock pages: */}
          {/*<Route path="/selected-papers" element={<SelectedPapers />} />*/}
          {/*<Route path="/uploads" element={<Uploads />} />*/}
          {/*<Route path="/product" element={<ProductDetails />} />*/}
          {/*<Route path="/charts" element={<Charts />} />*/}
          {/*<Route path="/people/group" element={<PeopleGroup />} />*/}
          {/*<Route path="/edit/group" element={<EditGroup />} />*/}
          {/*<Route path="/edit-experiment" element={<EditExperiment />} />*/}
          {/*<Route path="/form-group" element={<FormGroup />} />*/}

          <Route path="/404" element={<NotFound />} />
          <Route path="*" element={<Navigate replace to="/explore" />} />
        </Routes>
      </Router>
    </QueryClientProvider>
  </React.StrictMode>,
);
