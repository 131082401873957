import React from "react";
import PropTypes from "prop-types";
import Datepicker from "react-tailwindcss-datepicker";

import LoadingContainer from "components/loading";
import { Tags, TAG_SHAPE } from "components/tags/tags";

export default function Meta({
  isLoading,
  tags,
  tagsError,
  handleTagClick,
  range,
  setRange,
  allowedRange,
}) {
  return (
    <LoadingContainer isLoading={isLoading} error={tagsError}>
      <>
        <Tags
          tags={tags}
          onClick={handleTagClick}
          groupByCategory={true}
          className="border bg-neutral-bg dark:bg-dark-neutral-bg
                      border-neutral  dark:border-dark-neutral-border rounded-2xl
                      p-6 flex flex-col"
        />

        <div className="flex flex-col md:flex-row justify-between">
          <div className="min-w-[300px] ml-auto">
            <Datepicker
              primaryColor={"sky"}
              value={range}
              onChange={(newValue) => setRange(newValue)}
              minDate={allowedRange.startDate}
              maxDate={allowedRange.endDate}
              showShortcuts={true}
              startFrom={allowedRange.startDate}
            />
          </div>
        </div>
      </>
    </LoadingContainer>
  );
}
Meta.propTypes = {
  isLoading: PropTypes.bool,
  tags: PropTypes.objectOf(PropTypes.arrayOf(TAG_SHAPE)),
  tagsError: PropTypes.object,
  handleTagClick: PropTypes.func,
  range: PropTypes.shape({
    startDate: PropTypes.instanceOf(Date),
    endDate: PropTypes.instanceOf(Date),
  }),
  setRange: PropTypes.func,
  allowedRange: PropTypes.shape({
    startDate: PropTypes.instanceOf(Date),
    endDate: PropTypes.instanceOf(Date),
  }),
};
