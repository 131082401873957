import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { TABLE_SHAPE } from "./shapes";
import Row from "./row";

const Table = ({
  columns = [],
  data = [],
  onRowClick = () => { },
  rowActions = [],
  className = "",
  emptyDataMessage,
  ExpandedRowComponent = null,
}) => {
  const [expandedRowIdx, setExpandedRowIdx] = useState(null);
  const { t } = useTranslation();
  const isDataEmpty = data.length === 0;
  const dataLen = data.length;
  const hasActions = rowActions.length > 0;

  return (
    <div className="w-full overflow-auto">
      <table className={`w-full min-w-[600px] min-h-[100px] ${className}`}>
        <thead>
          <tr>
            {columns
              .filter(({ isHidden }) => !isHidden)
              .map(({ labelKey, name, className }, thIdx) => (
                <th
                  key={`${name}-${thIdx}`}
                  className={`text-gray-400 leading-4 font-normal text-left py-3 dark:text-gray-dark-400 text-m pr-4 ${className}`}
                >
                  {t(labelKey)}
                </th>
              ))}
            {hasActions && <th className="w-1/12" />}
          </tr>
        </thead>

        <tbody>
          {isDataEmpty && (
            <tr className="dark:border-dark-neutral-border border-t">
              <td
                className={"p-8 text-neutral-400 text-center"}
                colSpan={columns.length}
              >
                {emptyDataMessage || t("noData")}
              </td>
            </tr>
          )}

          {data.map((row, trIdx) => (
            <Row
              rowActions={rowActions}
              columns={columns}
              dataLen={dataLen}
              expandedRowIdx={expandedRowIdx}
              key={trIdx}
              row={row}
              setExpandedRowIdx={setExpandedRowIdx}
              trIdx={trIdx}
              ExpandedRowComponent={ExpandedRowComponent}
              onClick={onRowClick}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
};

Table.propTypes = TABLE_SHAPE;

export default Table;
