import React from "react";
import PropTypes from "prop-types";

import LineChartCustomized from "components/charts/line-chart";
import LoadingContainer from "components/loading";

import { CATEGORIES } from "api/data/constants";
import { useFetchSingleData } from "api/data/api-data-hooks";

const HydroFileExpanded = ({ row: { id } = {}, category, folderId } = {}) => {
  const {
    isFetched: isFileDataFetched,
    isLoading: isLoadingFileData,
    error: fileDataError,
    data: fileData,
  } = useFetchSingleData({
    category,
    folderId,
    fileId: id,
    enabled: true,
  });

  return (
    <LoadingContainer
      isLoading={isLoadingFileData || !isFileDataFetched}
      error={fileDataError}
      className="w-full flex flex-col shadow-inner py-8 items-center"
    >
      <div className="w-full flex flex-col shadow-inner py-8">
        <div className="w-full p-4 bg-gray-50 dark:bg-gray-800 rounded-md shadow-md mb-4">
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-x-4 gap-y-2">
            {Object.entries(fileData?.data?.meta || {}).map(([key, value]) => (
              <div
                key={key}
                className="flex justify-between border-b-[0.5px] pb-1"
              >
                <span className="text-sm font-medium text-gray-600 dark:text-gray-300">
                  {key}
                </span>
                <span className="text-sm text-gray-800 dark:text-gray-100">
                  {value}
                </span>
              </div>
            ))}
          </div>
        </div>
        <LineChartCustomized
          data={fileData?.data?.data || []}
          minDiff={-10}
          maxDiff={10}
          name="Total column ozone (DU)"
          xAxisKey="dates"
          key="values"
        />
      </div>
    </LoadingContainer>
  );
};

HydroFileExpanded.propTypes = {
  row: PropTypes.shape({
    id: PropTypes.string,
  }),
  category: PropTypes.oneOf(Object.values(CATEGORIES)),
  folderId: PropTypes.string,
};

export default HydroFileExpanded;
