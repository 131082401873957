import { useTranslation } from 'react-i18next';
import CONTENT_GEOMAGNETIC_PAPERS from "locales/content-geomagnetic.json";
import RelatedPage from 'pages/related-pages/related-page';
import { useState } from 'react';
const Measurement = () => {
    const { i18n, t } = useTranslation();
    const [show, setShow] = useState(false);
    const toggleActions = (e) => {
        e.stopPropagation();
        setShow(!show);
    };

    return (
        <div
            className="border bg-neutral-bg border-neutral dark:bg-dark-neutral-bg dark:border-dark-neutral-border
                  rounded-2xl p-8 scrollbar-hide lg:overflow-x-hidden"
        >
            <h2> {t("geomagnetic")} </h2>
            <p className="text-sm mt-2">
                {t("descriptionGeology")}
            </p>
            <button onClick={toggleActions} className="px-[10px] py-[10px] rounded-[3px] mt-4 border-4 outline-none
              border-color-brands bg-color-brands text-gray-100 dark:border-none dark:bg-gray-800 dark:text-gray-400 px-4 py-2 rounded-lg">
                {show ? t("hideDetails") : t("detail")}</button>


            {show && <div className="flex-grow flex flex-wrap py-4  dark:text-gray-500">
                <div className="h-full overflow-auto pt-3">
                    <div className="h-full mx-4 md:mx-0 overflow-x-hidden overflow-y-auto">
                        <RelatedPage
                            pages={CONTENT_GEOMAGNETIC_PAPERS.pages}
                            title={CONTENT_GEOMAGNETIC_PAPERS.title}
                            language={i18n.language}
                            isDescrItalic={false}
                        />

                        <button onClick={toggleActions} className="px-[10px] py-[10px] rounded-[3px] mt-4 border-4 outline-none
              border-color-brands bg-color-brands text-gray-100 dark:border-none dark:bg-gray-800 dark:text-gray-400 px-4 py-2 rounded-lg">
                            {t("hideDetails")} </button>

                    </div>
                </div>
            </div>}
        </div>
    )
}

export default Measurement