import React from "react";
import { TABLE_SHAPE } from "./shapes";
import Pagination, { PAGINATION_SHAPE } from "../pagination";
import Table from "./table";
import PropTypes from "prop-types";

const TableWithPagination = ({
  // pagination
  paginationClassName = "mt-[30px]",
  total,
  currentPage,
  setCurrentPage,
  itemsPerPage,
  setItemsPerPage,
  // table props
  data = [],
  ...otherProps
}) => {
  const showPagination = total > data.length;

  return (
    <>
      <Table data={data} {...otherProps} />

      {showPagination && (
        <Pagination
          className={paginationClassName}
          total={total}
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
          itemsPerPage={itemsPerPage}
          setItemsPerPage={setItemsPerPage}
        />
      )}
    </>
  );
};

TableWithPagination.propTypes = {
  ...PAGINATION_SHAPE,
  paginationClassName: PropTypes.string,
  ...TABLE_SHAPE,
};

export default TableWithPagination;
