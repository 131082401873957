export const DATA_KEYS = {
  DATA: "data",
  getFolderKeys: ({ category, folderId, offset, startDate, endDate, tags }) => [
    "data",
    category,
    folderId,
    offset,
    startDate,
    endDate,
    tags,
  ],
  getFileKeys: ({ category, folderId, fileId }) => [
    "data",
    category,
    folderId,
    fileId,
  ],
};

export const META_KEYS = {
  META: "meta",
  getFolderKeys: ({ category, folder }) => ["meta", category, folder],
};
