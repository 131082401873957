export const CATEGORIES = {
  // Геологія та геофізика,
  GEOLOGY_AND_GEOPHYSICS: "geology-and-geophysics",
  // Геокосмос,
  GEOCOSMOS: "geo-cosmos",
  // Гідрометеорологія,
  HYDROMETEOROLOGY: "hydrometeorology",
  // Океанографія,
  OCEANOGRAPHY: "oceanography",
  // Біологія,
  BIOLOGY: "biology",
  // Медицина та фізіологія
  MEDICINE_AND_PHYSIOLOGY: "medicine-and-physiology",
};


